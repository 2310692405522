/**
 * Sticky sidebars
 *
 * @todo componentize and encapsulate this,
 * we're assuming all sidebars have this offeset
 * and configs, but that might not be the case
 */

import '@vendor/javascripts/ResizeSensor';
import StickySidebar from 'sticky-sidebar';

export default function createStickySidebars() {
  // remove previous instances
  let instances = window.sidebarInstances || [];
  instances.forEach((sidebar) => sidebar.destroy());
  instances = [];

  const $stickyHeader = document.querySelector('[data-js-header-wrap]');
  const stickyHeaderHeight = $stickyHeader ? $stickyHeader.getBoundingClientRect().height : 0;

  const $sidebarElements = [].slice.call(document.querySelectorAll('[data-sticky-el]'));
  $sidebarElements.forEach((el) => {
    const topOffset = parseInt(el.getAttribute('data-offset-top'), 10);
    const bottomOffset = parseInt(el.getAttribute('data-offset-bottom'), 10);
    const options = {
      topSpacing: topOffset || stickyHeaderHeight,
      bottomSpacing: bottomOffset,
      containerSelector: '.js-sticky-container',
      innerWrapperSelector: '[data-sticky-inner], .c-sidebar__inner',
      resizeSensor: true,
      minWidth: 768,
    };

    instances.push(new StickySidebar(el, options));
  });

  window.sidebarInstances = instances;
}

document.addEventListener('DOMContentLoaded', () => {
  createStickySidebars();
});
